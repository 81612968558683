import { Dependencies } from 'constitute';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import { v4 as uuid } from 'uuid';

import SocketClient from '@/evercall/application/DI/Socket/Client';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';

import { IOaccConference } from '@/evercall/domain/vo/oacc/Conference';
import { IOaccRegistrationDetails } from '@/evercall/domain/vo/oacc/RegistrationDetails';
import { IDialIn } from '@/evercall/domain/vo/DialIn';
import { IOaccDashboardConference } from '@/evercall/domain/vo/oacc/DashboardConference';
import { OaccDashboardEventType } from '@/evercall/domain/vo/dashboard/Event';
import { ICustomRegistration } from '@/evercall/domain/vo/call/admin/CustomRegistration';
import { IOaccRegistrationGroup } from '@/evercall/domain/vo/oacc/RegistrationGroup';
import { IOaccDashboardParticipantListItem } from '@/evercall/domain/vo/oacc/DashboardParticipantListItem';

@Dependencies(SocketClient, RpcDispatcher)
class OaccRepository {
  constructor(
    private socketClient: typeof SocketClient,
    private rpc: typeof RpcDispatcher,
    private dashboardSubscription: Subscription,
    private exportParticipantsSubscription: Subscription,
  ) {}

  findConference = async (payload: { id: string }): Promise<IOaccConference> => {
    const response = await this.rpc.request(new Request('evercall.oacc.find_conference', payload));
    return response.getResult().payload;
  };

  retrieveDashboardConference = async (payload: { dashboardId: string }): Promise<string | null> => {
    const response = await this.rpc.request(new Request('evercall.oacc.retrieve_dashboard_live_conference', payload));
    return response.getResult().payload;
  };

  subscribeToConference = async (
    {
      onDashboardUpdate,
      conferenceId,
    }: {
      conferenceId: string,
      onDashboardUpdate: (payload: {
        totalLiveParticipants: number,
        liveConferences: IOaccDashboardConference[],
      }) => void,
    },
  ) => {
    this.dashboardSubscription = await this.socketClient.subscribe(
      new Request('evercall.oacc.conference_dashboard', { conferenceId }),
    );

    this.dashboardSubscription.on(
      OaccDashboardEventType.LiveConferenceUpdated,
      (data: {
        params: {
          payload: {
            totalLiveParticipants: number,
            liveConferences: IOaccDashboardConference[],
          },
        },
      }) => onDashboardUpdate(data.params.payload),
    );
  };

  unsubscribeFromConference = (): Promise<any> => {
    if (this.dashboardSubscription instanceof Subscription) {
      return this.dashboardSubscription.cancel();
    }
    return null;
  };

  bindConference = async (payload: { conferenceId: string, dashboardId: string }): Promise<null> => {
    const response = await this.rpc.request(new Request('evercall.oacc.bind_dashboard_live_conference', payload));
    return response.getResult().payload;
  };

  unbindConference = async (payload: { conferenceId: string, dashboardId: string }): Promise<null> => {
    const response = await this.rpc.request(new Request('evercall.oacc.unbind_dashboard_live_conference', payload));
    return response.getResult().payload;
  };

  /**
   * This method is called only if the current user is Operator.
   */
  callConference = async (payload: { conferenceId: string }): Promise<null> => {
    const response = await this.rpc.request(new Request('evercall.oacc.call_conference_dashboard', payload));
    return response.getResult().payload;
  };

  registerParticipantToConference = async (
    { conferenceId, ssid }: { conferenceId: string, ssid: string },
  ): Promise<IOaccRegistrationDetails> => {
    const response = await this.rpc.request(
      new Request(
        'evercall.oacc.register_conference_participant_evercall', { conferenceId }, { ssid }),
    );
    return response.getResult().payload;
  };

  getDialInPhones = async (): Promise<IDialIn[]> => {
    const response = await this.rpc.request(new Request('evercall.oacc.fetch_intl_dial_ins', {}));
    return response.getResult().payload;
  };

  getExportConferenceToCalendarUrl = async (payload: IOaccRegistrationDetails): Promise<string> => {
    const response = await this.rpc.request(new Request('evercall.oacc.generate_call_details_icalendar_link', payload));
    return response.getResult().payload;
  };

  getExportConferenceToCalendarUrlRoadshow = async (payload: IOaccRegistrationDetails): Promise<string> => {
    const response = await this.rpc.request(
      new Request('evercall.viewer.oacc.generate_participant_conference_details_calendar', payload),
    );
    return response.getResult().payload;
  };

  sendDetailsViaEmailRoadshow = async (payload: IOaccRegistrationDetails): Promise<null> => {
    const response = await this.rpc.request(
      new Request('evercall.viewer.oacc.send_participant_conference_details_email', payload),
    );
    return response.getResult().payload;
  };

  /**
   * Get Evercall conference details
   */
  getEvercallDetailsRoadshow = async (payload: { roadshowId: string }): Promise<IOaccRegistrationDetails | null> => {
    let req = new Request('evercall.viewer.oacc.find_or_register_participant_conference_details', payload);
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  };

  getExportParticipantsUrl = async (payload: {
    conferenceId: string,
    liveParticipants: IOaccDashboardParticipantListItem[],
    subconferences: IOaccDashboardConference[],
  }): Promise<string> => {
    const entityId = uuid();
    const req = new Request('evercall.oacc.generate_dashboard_excel_link', { ...payload, entityId });

    const socketSubscribeReq = new Request('evercall.socket.notifications', { entityId });
    this.exportParticipantsSubscription = await this.socketClient.subscribe(socketSubscribeReq);

    await this.rpc.request(req);

    return new Promise((resolve, reject) => {
      this.exportParticipantsSubscription.on(
        'evercall.export_generation.dashboard',
        ({ params: { payload: { url, error } } }) => {
          this.exportParticipantsSubscription.cancel();

          if (url) {
            resolve(url);
          } else {
            reject(error);
          }
        },
      );
    });
  };

  getCallCustomRegistrationSettingsByConferenceId = async (
    payload: { conferenceId: string },
  ): Promise<ICustomRegistration> => {
    const req = new Request('evercall.oacc.get_call_appearance_settings', payload);
    const response = await this.rpc.request(req);
    return response.getResult().payload;
  };

  getRegistrationGroup = async (payload: { path: string }): Promise<IOaccRegistrationGroup> => {
    const response = await this.rpc.request(new Request('evercall.oacc.get_group', payload));
    return response.getResult().payload;
  };
}

export default OaccRepository;
