import { useState } from 'react';
import getActions from './getActions';
import { IDialIn } from '@/evercall/domain/vo/DialIn';

export default (): { state: { phoneList: Array<IDialIn> } } & ReturnType<typeof getActions> => {
  const [phoneList, setPhoneList] = useState<Array<IDialIn>>([]);

  return {
    state: {
      phoneList,
    },
    ...getActions({ setPhoneList }),
  };
};
