import OaccRepository from '@/evercall/infrastructure/repository/oacc/OaccRepository';
import createAsyncRequest from '@/Framework/State/createAsyncRequest';
import { useDIContext } from '@/Framework/DI/DIContext';
import { IDialIn } from '@/evercall/domain/vo/DialIn';

interface IProps {
  setPhoneList: (payload: Array<IDialIn>) => void,
}

export default ({ setPhoneList }: IProps) => {
  const { container } = useDIContext();
  const oaccRepository: OaccRepository = container.get(OaccRepository);

  const getCountryPhoneList = createAsyncRequest<typeof oaccRepository.getDialInPhones>({
    method: oaccRepository.getDialInPhones,
    callbacks: {
      onSuccess: ({ response }) => setPhoneList(response),
    },
  });

  return {
    getCountryPhoneList,
  };
};
