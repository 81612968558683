import React, { useEffect } from 'react';
import { Popover, Spinner } from '@dealroadshow/uikit';
import DialInItem from './DialInItem';
import useFeatures from '@/evercall/application/features/useFeatures';
import styles from './dialInsPopover.scss';

interface IProps {
  children: React.ReactNode,
}

const DialInsPopover = ({ children }: IProps) => {
  const {
    state: { phoneList },
    getCountryPhoneList,
  } = useFeatures();

  useEffect(() => {
    getCountryPhoneList();
  }, []);

  const updatedList = phoneList.reduce((acc, item) => {
    if (!acc.find((v) => v.countryCode === item.countryCode)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const dialInsList =
    updatedList.length > 0 ? (
      updatedList.map((item) => (
        <DialInItem
          key={ item.countryCode }
          countryCode={ item.countryCode }
          countryName={ item.countryName }
          phoneCode={ item.phoneCode }
        />
      ))
    ) : (
      <Spinner />
    );

  return (
    <Popover
      content={ dialInsList }
      contentClassName={ styles.popoverContainer }
      wrapperClassName={ styles.popover }
      triggerEvent="hover"
    >
      <span className={ styles.popoverText }>
        { children }
      </span>
    </Popover>
  );
};

export default DialInsPopover;
