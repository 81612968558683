import React from 'react';
import cn from 'classnames';
import Image from '@/Framework/Router/Next/Image';
import { variantTypes } from '@/Framework/UI/Atoms/Button';

import baseStyles from '../base.scss';
import styles from './customers.scss';

export interface ICustomer {
  src: string,
  title: string,
  width?: number,
  height?: number,
}

interface IButtonProps {
  variant: string,
  className: string,
}

interface IProps {
  customers: ICustomer[],
  showMoreButton?: React.ComponentType<IButtonProps>,
  innerClassName?: string,
}

const Customers = ({ customers, showMoreButton: ShowMoreButton, innerClassName }: IProps) => {
  const buttonProps: IButtonProps = {
    variant: variantTypes.action,
    className: cn(baseStyles.actionButton, styles.exploreButton),
  };

  return (
    <>
      <div className={ cn(styles.customersInner, innerClassName) }>
        <div className={ styles.customersLogos }>
          { customers.map((customer) => (
            <div
              key={ customer.title }
              className={ styles.customer }
            >
              <Image
                src={ customer.src }
                title={ customer.title }
                alt={ customer.title }
                width={ customer.width || 115 }
                height={ customer.height || 30 }
              />
            </div>
          )) }
          { ShowMoreButton && <span className={ styles.shadow } /> }
        </div>
        { ShowMoreButton && (
          <div className={ styles.customersButton }>
            <ShowMoreButton { ...buttonProps } />
          </div>
        ) }
      </div>
    </>
  );
};

export default Customers;
